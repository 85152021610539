import { SDBN1_ROUTES } from "@coimbra-its/api.sdbn1";
import { WSysApi } from "@coimbra-its/websys-ux-core";
import { createContext, useContext } from "react";



export const Sdbn1Api = createContext(new WSysApi(process.env.REACT_APP_API_BASE_URL + '' || '', SDBN1_ROUTES,
  headers => {
      /*const sessionId = useSessionId();
      (headers as any)['authorization'] = `Bearer ${sessionId}`;*/
  }
));

export type TSdbn1Api = typeof Sdbn1Api;


export function useSdbn1Api() {
  const api = useContext(Sdbn1Api);
  return api.ep;
}