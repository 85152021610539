import { IWSysHub, IWSysHubEvent } from '@coimbra-its/websys-ux-core'
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

import type { SIWECreateMessageArgs, SIWESession, SIWEVerifyMessageArgs } from '@web3modal/siwe'
import { createSIWEConfig, formatMessage } from '@web3modal/siwe'
import { SiweMessage } from 'siwe'
import { TSdbn1Api, useSdbn1Api } from '../store/sdbn1.api'
import { useState } from 'react'

// 1. Get projectId
export const projectId = 'ec2399d45d6d12326a12a50a09384a5b' // Kristóf
//export const projectId = '63242127221a9365f9f87e48d552536d' // Péter

// 2. Set chains
/* TEST:
const mainnet = {
	chainId: 0x61,
	name: 'Binance Smart Chain Testnet',
	currency: 'tBNB',
	explorerUrl: 'https://testnet.bscscan.com',
	rpcUrl: 'https://data-seed-prebsc-1-s3.binance.org:8545'
}*/

export const mainnet = {
	chainId: 0x38,
	name: 'Binance Smart Chain',
	currency: 'BNB',
	explorerUrl: 'https://bscscan.com/',
	rpcUrl: 'https://bsc-dataseed1.binance.org/',
}

// 3. Create a metadata object
export const metadata = {
	name: 'sunmoney-walletconect-demo',
	description: 'Sunmoney Walletconnect demo',
	//url: 'https://walletconnect-demo.sunmoney.net', // origin must match your domain & subdomain
	//icons: ['https://walletconnect-demo.sunmoney.net/icons']
	url: 'https://token.sdbn1.com', // origin must match your domain & subdomain
	icons: ['https://token.sdbn1.com/icons']
}

// 4. Create Ethers config
export const ethersConfig = defaultConfig({
	/*Required*/
	metadata,
	//enableEmail: true,
	/*Optional*/
	enableEIP6963: true, // true by default
	enableInjected: true, // true by default
	enableCoinbase: true, // true by default
	rpcUrl: mainnet.rpcUrl, //??   '...', // used for the Coinbase SDK
	defaultChainId: mainnet.chainId,  //??  1, // used for the Coinbase SDK
})



async function getMessageParams() {
	return {
		domain: window.location.host,
		uri: window.location.origin,
		chains: [mainnet.chainId],
		statement: 'Please sign with your account'
	}
}

// ==========================================================================================================
// ---------------------------------------------- SIWE ------------------------------------------------------
// ==========================================================================================================

// (sign in with ethers) https://docs.walletconnect.com/web3modal/react/siwe



export function useCreateWeb3Modal(hub: IWSysHub) {

	const api = useSdbn1Api();
	const signInPost = api.signInPost.useAction();
	const signOutPost = api.signOutPost.useAction();
	const startupPost = api.startupPost.useAction();

	const [modalCreated, setModalCreated] = useState(false);

	if (modalCreated)
		return;
	setModalCreated(true);

	// ----------------- from here run once ----------------

	const siweConfig = createSIWEConfig({
		// ----- ez a verziót ajánlja a dokumentáció:  https://docs.walletconnect.com/appkit/vue/core/siwe
		getMessageParams,
		createMessage: ({ address, ...args }: SIWECreateMessageArgs) => {
			console.log('-------*** createMessage ***', address, args);
			let msg = formatMessage(args, address);
			// TODO:  valmiért undefined-ként adta vissza az "Issued At"-et, pedig a példák szerint (is) kell
			msg = msg.replace('Issued At: undefined', `Issued At: ${new Date().toISOString()}`);
			console.log('-------  formatMessage ----------', msg);
			return msg;
		},
		getNonce: async (address?: string) => {
			console.log('-------*** getNonce ***', address);
			if (!address)
				throw new Error('No address');

			/*const resp = await fetch(API_BASE + '/startup', {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					walletAddress: address
				})
			});
			const res = (await resp.json()) as IStartupPostApi['response']['payload'];*/
			const loadingEvent: IWSysHubEvent = { severity: 'loading', message: 'Loading...' };
			hub.addEvent(loadingEvent);
			try {
				const res = await startupPost({ walletAddress: address, nonce: 'minekide???' });
				console.log('ide eljutok??');
				if (!res.nonce)
					throw new Error('No nonce!');
				window.localStorage.setItem('sdbn1_wallet', address);
				window.localStorage.setItem('sdbn1_nonce', res.nonce);
				window.localStorage.setItem('sdbn1_nonce_is_signed', '0');
				return res.nonce;
			} catch (ex) {
				console.error(ex);
				throw ex;
			} finally {
				hub.removeEvent(loadingEvent);
			};


		},
		getSession: async () => {
			console.log('-------*** getSession ***');
			//TODO: Itt kéne aszinkron ellenőrizni, hogy él-e session szerver oldalon?
			const sess_address = window.localStorage.getItem('sdbn1_wallet');
			const sess_sdbn1_nonce_is_signed = window.localStorage.getItem('sdbn1_nonce_is_signed') === '1';
			if (sess_address && sess_sdbn1_nonce_is_signed) {
				const sess: SIWESession = {
					address: sess_address,
					chainId: mainnet.chainId //TODO!!
				}
				return sess;
			} else {
				return null;
			}
		},
		verifyMessage: async (args: SIWEVerifyMessageArgs) => {
			console.log('-------*** verifyMessage ***', args);

			const sess_address = window.localStorage.getItem('sdbn1_wallet');
			const sess_nonce = window.localStorage.getItem('sdbn1_nonce');
			console.log(sess_address, sess_nonce);

			/*const resp = await fetch(API_BASE + '/signin', {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					walletAddress: sess_address,
					nonce: sess_nonce,
					nonceSigned: args.signature,
					message: args.message,
				})
			});
			const res = (await resp.json()) as IStartupPostApi['response']['payload'];*/
			const loadingEvent: IWSysHubEvent = { severity: 'loading', message: 'Loading...' };
			hub.addEvent(loadingEvent);
			try {
				const res = await signInPost({
					walletAddress: sess_address || '',
					nonce: sess_nonce || '',
					nonceSigned: args.signature,
					message: args.message,
				})
				console.log('---- verifyMessage vége....', res);
				window.localStorage.setItem('sdbn1_nonce_is_signed', '1');
				return true;

			} finally {
				hub.removeEvent(loadingEvent);
			}

		},
		signOut: async () => {
			const sess_address = window.localStorage.getItem('sdbn1_wallet');
			const sess_nonce = window.localStorage.getItem('sdbn1_nonce');

			/*const resp = await fetch(API_BASE + '/signout', {
				method: 'POST',
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					walletAddress: sess_address,
					nonce: sess_nonce,
				})
			});*/
			const loadingEvent: IWSysHubEvent = { severity: 'loading', message: 'Loading...' };
			hub.addEvent(loadingEvent);
			try {
				await signOutPost({
					walletAddress: sess_address || '',
					nonce: sess_nonce || '',
				})
				//window.localStorage.removeItem('sdbn1_wallet');
				//window.localStorage.removeItem('sdbn1_nonce');
				window.localStorage.removeItem('sdbn1_nonce_is_signed');
				return true
			} finally {
				hub.removeEvent(loadingEvent);
			}
		},
	})

	// 5. Create a Web3Modal instance
	console.log('ITTT VAGYOK!!!');
	createWeb3Modal({
		ethersConfig,
		chains: [mainnet],
		projectId,
		enableAnalytics: false, // Optional - defaults to your Cloud configuration
		//siweConfig,
	})

}

//const USDTAddress = '0x617f3112bf5397D0467D315cC709EF968D9ba546'
export const usdt_address = "0x55d398326f99059fF775485246999027B3197955";  //ok
//sdbn2plus hoz
//const payout_address = "0x62F915d3bCE0119862d3d68C1649341b930c1719";
//sdbn1 hez
//export const payout_address = "0xE7D161a581c0Ee4a351d13d3b7Ac26E5Ac346Cf2";
//export const payout_address = "0x956238D6835e4Dc9e038f01cE02CaE70e8998a6c";
export const payout_address = "0xa737F3B9f5E73344770B76B0F39c63517968966C";

// régiből:
export const sale_address = "0x296682C515EAaB48F13F38C74797B0F40407B46e"; //... buy
export const token_address = "0xC605eac37a26927F38284387048AaF1395B1D9e3"; //ok
export const PAYOUT_IGNORE_ACCOUNTS = [sale_address];

// ------------- TODO !!!!!!!!!!!!!!!!!!!!
export const ADMINS = [
	'0xDE33d58193Cdec2a6383649353A2bFbFB037c4ed'
	, '0x4E2f6cE4D448d682AB0a7D6d88505179aFDba838'
	, '0x6462d1e470882b44E7cDD7D4CE892b40740D411C'
	, '0xc548D809C22a561c1BE9072D77A9B78CF6b8519F'
];
